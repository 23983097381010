(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name informacio.people.controller:PeopleCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.ajuntaments.ajuntament.informacio.people')
  .controller('AjuntamentPeopleCtrl', AjuntamentPeopleCtrl);

  function AjuntamentPeopleCtrl(users) {
    var vm = this;
    vm.ctrlName = 'AjuntamentPeopleCtrl';
    vm.users = users;
  }
}());
